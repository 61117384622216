import { axiosAnonInstance, axiosInstance } from "./api.js";
import apiConstants from "constants/apiConstants.js"

const getUrl = (url) => {
  return `${apiConstants.foundation}${url}`;
}

export default {
  newDonationAnon: (request) => axiosAnonInstance.post(getUrl('/api/foundation/donation'), {
    // Mapeo innecesario, pero describe el formato del body
    amount: request.amount,
    donation_type_id: request.donationTypeId,
    new_user: {
          password : request.password,
          dni: request.dni,
          name : request.name,
          family_name: request.surname,
          gender : "2",
          email : request.username,
          phone_number : request.phoneNumber,
          birthdate: request.birthdate
    }
  }),
  newDonation: (request) => axiosInstance.post(getUrl('/api/foundation/donation/user'), {
    // Mapeo innecesario, pero describe el formato del body
    amount: request.amount,
    donation_type_id: request.donationTypeId,
    new_user: null
  }),
  getDonations: () => axiosInstance.get(getUrl('/api/foundation/donation/get-donations'))
};
