import { get } from "lodash-es";
import { createActions } from "redux-actions";
import apiConstants from "constants/apiConstants.js";
import { setDonationAmount, getDonationAmount } from "utils/donationStorage";
import { normalizeError } from "../../utils/requestErrorNormalizer";

// ------------------- //
// ACTIONS SINCRÓNICOS //
// ------------------- //

const actionOptions = {
  prefix: "DONATION", // Prefijo usado para los actions creados
  namespace: "-" // Separador usado entre el prefijo y el nombre del action
  // No usar "_" como separador, o se rompe el naming de las variables
};

const defaultError =
  "En este momento no podemos procesar su solicutud, por favor intente más tarde.";

export const {
  postDonationStart,
  postDonationSuccess,
  postDonationFail,
  postDonationReset,
  getDonationsStart,
  getDonationsSuccess,
  getDonationsFail
} = createActions(
  {
    POST_DONATION_START: () => undefined,
    POST_DONATION_SUCCESS: (token, expirationDate) => ({
      token: token,
      expirationDate: expirationDate
    }),
    POST_DONATION_FAIL: error => ({
      message: error
    }),
    POST_DONATION_RESET: () => undefined,
    GET_DONATIONS_START: () => undefined,
    GET_DONATIONS_SUCCESS: donationAmount => ({
      donationAmount
    }),
    GET_DONATIONS_FAIL: error => ({
      message: error
    })
  },
  actionOptions
);

// -------------------- //
// ACTIONS ASINCRÓNICOS //
// -------------------- //

// Dispatch es la funcion dispatcher que ejecutará la acción generada
// getState es un método de React-Redux, devuelve el estado del store antes del dispatch
// api es un parametro extra enviado desde el middleware del store, usando thunk.withExtraArgument()
export function postDonationAnon(
  amount,
  username,
  donationTypeId,
  password,
  dni,
  name,
  surname,
  phoneNumber,
  birthdate
) {
  return async (dispatch, getState, api) => {
    await dispatch(postDonationStart());

    const request = {
      amount,
      username,
      donationTypeId,
      password,
      dni,
      name,
      surname,
      phoneNumber,
      birthdate
    };
    console.log(request);
    return api.donation
      .newDonationAnon(request)
      .then(async response => {
        const { success, message, url, expirationdate } = response.data;
        if (success) {
          dispatch(postDonationSuccess(url, expirationdate));
          window.location = url;
        } else {
          dispatch(postDonationFail(message));
        }
      })
      .catch(error => {
        console.log("Error:", error);
        const message = get(error, "response.data.message", defaultError);
        dispatch(postDonationFail(normalizeError(message, defaultError)));
      });
  };
}

export function postDonation(amount, donationTypeId) {
  return async (dispatch, getState, api) => {
    await dispatch(postDonationStart());

    const request = {
      amount,
      donationTypeId
    };

    return api.donation
      .newDonation(request)
      .then(async response => {
        const { success, message, url, expirationdate } = response.data;
        if (success) {
          dispatch(postDonationSuccess(url, expirationdate));
          window.location = url;
        } else {
          dispatch(postDonationFail(message));
        }
      })
      .catch(error => {
        console.log("Error:", error);
        const message = get(error, "response.data.message", defaultError);
        dispatch(postDonationFail(normalizeError(message, defaultError)));
      });
  };
}

export function getDonations() {
  return async (dispatch, getState, api) => {
    await dispatch(getDonationsStart());

    const isFake = getState().auth.userInfo.isFake;
    if (isFake) {
      await setDonationAmount(35000);
      dispatch(getDonationsSuccess(35000));
      return;
    }

    return api.donation
      .getDonations()
      .then(async response => {
        await setDonationAmount(response.data);
        dispatch(getDonationsSuccess(response.data));
      })
      .catch(error => {
        console.log("Error:", error);
        const message = get(error, "response.data.message", defaultError);
        dispatch(postDonationFail(normalizeError(message, defaultError)));
      });
  };
}

export function loadDonationsFromStorage() {
  return async (dispatch, getState, api) => {
    if (getState().auth.isAuthenticated) {
      const donations = getDonationAmount();
      dispatch(getDonationsSuccess(donations));
    }
  };
}
