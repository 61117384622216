/* eslint-disable no-unused-vars */
import { showModal, hideModal } from "./actions";

import { handleActions } from "redux-actions";

const initialState = {
  modalType: "",
  modalProps: {}
};

const modal = handleActions(
  new Map([
    [
      showModal,
      (state, action) => ({
        ...state,
        modalType: action.payload.modalType,
        modalProps: action.payload.modalProps
      })
    ],
    [
      hideModal,
      (state, action) => ({
        initialState
      })
    ]
  ]),
  initialState
);

export default modal;
