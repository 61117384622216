import routeList from "./routeList";
import React, { Suspense } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
// import SuspenseFallback from "components/common/suspenseFallback/suspenseFallback";

const SuspenseFallback = () => <div> </div>;

export const AppRoutes = props => {
  let routes = (
    <Suspense fallback={<SuspenseFallback />}>
      <Switch>
        {routeList.map((route, key) => {
          if (route.loggedIn === props.isAuthenticated || route.public){
            return (
              <Route exact path={route.path} component={route.component} key={key} />
            );
          }
          return null;
        })}
        <Redirect exact from="/" to={props.isAuthenticated ? "/home" : "/home" } />
      </Switch>
    </Suspense>
  )

  return routes
}

export default AppRoutes;
