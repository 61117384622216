import { 
  postDonationStart,
  postDonationSuccess,
  postDonationFail,
  postDonationReset,
  getDonationsStart,
  getDonationsSuccess,
  getDonationsFail
} from "./actions";

import { handleActions } from "redux-actions";

const initialState = {
  token: null,
  expirationDate: null,
  postDonationError: null,
  postDonationSubmitting: false,
  getDonationsError: null,
  getDonationsFetching: false,
  donationAmount: 0
};

const donation = handleActions(
  new Map([
    [
      postDonationStart,
      (state, action) => ({
        ...state,
        postDonationSubmitting: true,
        postDonationError: null
      })
    ],
    [
      postDonationSuccess,
      (state, action) => ({
        ...state,
        token: action.payload.token,
        expirationDate: action.payload.expirationDate,
        postDonationSubmitting: false
      })
    ],
    [
      postDonationFail,
      (state, action) => ({
        ...state,
        postDonationSubmitting: false,
        postDonationError: action.payload.message
      })
    ],
    [
      postDonationReset,
      (state, action) => ({
        ...state,
        token: null,
        expirationDate: null,
        postDonationError: null,
        postDonationSubmitting: false
      })
    ],
    [
      getDonationsStart,
      (state, action) => ({
        ...state,
        getDonationsFetching: true,
        getDonationsError: null
      })
    ],
    [
      getDonationsSuccess,
      (state, action) => ({
        ...state,
        donationAmount: action.payload.donationAmount,
        getDonationsFetching: false,
      })
    ],
    [
      getDonationsFail,
      (state, action) => ({
        ...state,
        getDonationsFetching: false,
        getDonationsError: action.payload.message,
        donationAmount: 0
      })
    ]
  ]),
  initialState
);

export default donation;