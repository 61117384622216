import { get } from "lodash-es";
import { createActions } from "redux-actions";

// ------------------- //
// ACTIONS SINCRÓNICOS //
// ------------------- //

const actionOptions = {
  prefix: "MOVIE",
  namespace: "-"
};

export const {
  fetchLastMoviesStart,
  fetchLastMoviesSuccess,
  fetchLastMoviesFail,
  fetchMostRelevantMoviesStart,
  fetchMostRelevantMoviesSuccess,
  fetchMostRelevantMoviesFail,
  fetchAllMoviesStart,
  fetchAllMoviesSuccess,
  fetchAllMoviesFail
} = createActions(
  {
    FETCH_LAST_MOVIES_START: () => undefined,
    FETCH_LAST_MOVIES_SUCCESS: (data, noMoreMovies, skip) => ({
      data: data,
      noMoreMovies: noMoreMovies,
      skip: skip
    }),
    FETCH_LAST_MOVIES_FAIL: error => ({
      message: error
    }),
    FETCH_MOST_RELVANT_MOVIES_START: () => undefined,
    FETCH_MOST_RELVANT_MOVIES_SUCCESS: data => ({
      data: data
    }),
    FETCH_MOST_RELVANT_MOVIES_FAIL: error => ({
      message: error
    }),
    FETCH_ALL_MOVIES_START: () => undefined,
    FETCH_ALL_MOVIES_SUCCESS: data => ({
      data: data
    }),
    FETCH_ALL_MOVIES_FAIL: error => ({
      message: error
    })
  },
  actionOptions
);
// -------------------- //
// ACTIONS ASINCRÓNICOS //
// -------------------- //

export function fetchLastMovies(moviesCount) {
  return async (dispatch, getState, api) => {
    var skip = getState().movie.skip;
    await dispatch(fetchLastMoviesStart());
    return api.movie
      .getLastMovies(moviesCount, skip)
      .then(async response => {
        var list = getState().movie.moviesList;
        var data =
          list.length > 0 && skip > 0
            ? list.concat(response.data)
            : response.data;
        var noMoreMovies = response.data.length < moviesCount;
        dispatch(
          fetchLastMoviesSuccess(data, noMoreMovies, skip + moviesCount)
        );
      })
      .catch(error => {
        console.log("Error:", error);
        const message = get(
          error,
          "response.data.message",
          "Ocurrio un error al intentar obtener las películas."
        );
        dispatch(fetchLastMoviesFail(message));
      });
  };
}

export function fetchMostRelevantMovies(moviesCount) {
  return async (dispatch, getState, api) => {
    await dispatch(fetchMostRelevantMoviesStart());
    return api.movie
      .getMostRelevantMovies(moviesCount)
      .then(async response => {
        dispatch(fetchMostRelevantMoviesSuccess(response.data));
      })
      .catch(error => {
        console.log("Error:", error);
        const message = get(
          error,
          "response.data.message",
          "Ocurrio un error al intentar obtener las películas."
        );
        dispatch(fetchMostRelevantMoviesFail(message));
      });
  };
}

export function fetchAllMovies() {
  return async (dispatch, getState, api) => {
    await dispatch(fetchAllMoviesStart());
    return api.movie
      .getAllMovies()
      .then(async response => {
        dispatch(fetchAllMoviesSuccess(response.data));
      })
      .catch(error => {
        console.log("Error:", error);
        const message = get(
          error,
          "response.data.message",
          "Ocurrio un error al intentar obtener las películas."
        );
        dispatch(fetchAllMoviesFail(message));
      });
  };
}
