import { get } from "lodash-es";
import { createActions } from "redux-actions";
import {
  getAuthData,
  getUserData,
  setAuthData,
  setUserData,
  getAuthToken
} from "utils/auth";
import {
  loadDonationsFromStorage,
  getDonationsSuccess
} from "store/donation/actions";
import { normalizeError } from "utils/requestErrorNormalizer";
import { setDonationAmount } from "utils/donationStorage";
import apiConstants from "constants/apiConstants.js";

// ------------------- //
// ACTIONS SINCRÓNICOS //
// ------------------- //

const actionOptions = {
  prefix: "AUTH", // Prefijo usado para los actions creados
  namespace: "-" // Separador usado entre el prefijo y el nombre del action
  // No usar "_" como separador, o se rompe el naming de las variables
};

export const {
  authenticate,
  unauthenticate,
  saveUser,
  clearUser,
  loginStart,
  loginSuccess,
  loginFail,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFail,
  forgotPasswordEmailStart,
  forgotPasswordEmailSuccess,
  forgotPasswordEmailFail,
  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordFail,
  updateProfileStart,
  updateProfileSuccess,
  updateProfileFail,
  updateProfileReset
} = createActions(
  {
    AUTHENTICATE: (token, expiresIn) => ({
      token,
      expiresIn
    }),
    UNAUTHENTICATE: () => undefined,
    SAVE_USER: userInfo => ({
      isFake: userInfo.isFake,
      username: userInfo.username,
      gender: userInfo.gender,
      profile_picture: userInfo.profile_picture,
      neighbourhoodpropertiesregistrationcode:
        userInfo.neighbourhoodpropertiesregistrationcode,
      personid: userInfo.personid,
      name: userInfo.name,
      family_name: userInfo.family_name,
      state: userInfo.state,
      email: userInfo.email,
      phone_number: userInfo.phone_number,
      dni: userInfo.dni,
      birthdate: userInfo.birthdate,
      isunderage: userInfo.isunderage
    }),
    CLEAR_USER: () => undefined,
    LOGIN_START: () => undefined,
    LOGIN_SUCCESS: data => ({
      data: data
    }),
    LOGIN_FAIL: error => ({
      message: error
    }),
    CHANGE_PASSWORD_START: () => undefined,
    CHANGE_PASSWORD_SUCCESS: () => undefined,
    CHANGE_PASSWORD_FAIL: error => ({
      message: error
    }),
    FORGOT_PASSWORD_EMAIL_START: () => undefined,
    FORGOT_PASSWORD_EMAIL_SUCCESS: () => undefined,
    FORGOT_PASSWORD_EMAIL_FAIL: error => ({
      message: error
    }),
    FORGOT_PASSWORD_START: () => undefined,
    FORGOT_PASSWORD_SUCCESS: () => undefined,
    FORGOT_PASSWORD_FAIL: error => ({
      message: error
    }),
    UPDATE_PROFILE_START: () => undefined,
    UPDATE_PROFILE_SUCCESS: data => ({
      data: data
    }),
    UPDATE_PROFILE_FAIL: error => ({
      message: error
    }),
    UPDATE_PROFILE_RESET: () => undefined
  },
  actionOptions
);

// -------------------- //
// ACTIONS ASINCRÓNICOS //
// -------------------- //

// Dispatch es la funcion dispatcher que ejecutará la acción generada
// getState es un método de React-Redux, devuelve el estado del store antes del dispatch
// api es un parametro extra enviado desde el middleware del store, usando thunk.withExtraArgument()
export function logIn(email, password) {
  return async (dispatch, getState, api) => {
    await dispatch(loginStart());

    // Fake login
    if (
      apiConstants.enableFakeData &&
      email === "admin" &&
      password === "123456"
    ) {
      const fakeToken = "FAKETOKEN";
      const fakeExpires = 999999999;
      const fakeUser = {
        isFake: true,
        username: "7460f7cb-9bc5-4be0-b4e5-c7ad3e92db2d",
        gender: 0,
        profile_picture:
          "https://s3.us-east-2.amazonaws.com/miiiisa-app/masterdata/usrimg/637279016172747102-dmICqX.png",
        neighbourhoodpropertiesregistrationcode: null,
        personid: 5232,
        name: "´Tomás",
        family_name: "Zeta",
        state: 2,
        email: "tcostantini@miiii.app",
        phone_number: "+1125997494",
        dni: "37350559",
        birthdate: null,
        isunderage: false
      };
      await setAuthData(true, fakeToken, fakeExpires);
      await setUserData(fakeUser);
      dispatch(authenticate(fakeToken, fakeExpires));
      dispatch(saveUser(fakeUser));
      dispatch(loginSuccess());
      return;
    }

    return api.auth
      .login(email, password)
      .then(async response => {
        await setAuthData(
          true,
          response.data.auth.access_token,
          response.data.auth.expiration_date
        );
        await setUserData(response.data.user);
        dispatch(
          authenticate(
            response.data.auth.access_token,
            response.data.auth.expiration_date
          )
        );
        dispatch(saveUser(response.data.user));
        dispatch(loginSuccess());
      })
      .catch(error => {
        console.log("Error:", error);
        const message = get(
          error,
          "response.data.message",
          "Ocurrio un error al intentar iniciar sesión."
        );
        dispatch(
          loginFail(
            normalizeError(
              message,
              "Ocurrio un error al intentar iniciar sesión."
            )
          )
        );
      });
  };
}

export function logOut() {
  return async (dispatch, getState, api) => {
    await setAuthData(false, "", "");
    await setUserData({});
    await setDonationAmount(0);
    dispatch(unauthenticate());
    dispatch(clearUser());
    dispatch(getDonationsSuccess(0));
  };
}

export function checkAuthentication() {
  return async (dispatch, getState, api) => {
    const auth = await getAuthData();
    const user = await getUserData();
    if (user == null || auth == null) {
      dispatch(unauthenticate());
      dispatch(clearUser());
      return;
    }

    const isAuthenticated =
      typeof auth.authenticated === "string" && auth.authenticated === "true";
    const token = auth.token;
    const expiresIn = auth.expiresIn;

    if (isAuthenticated) {
      dispatch(authenticate(token, expiresIn));
      dispatch(saveUser(user));
      dispatch(loadDonationsFromStorage());
    } else {
      dispatch(unauthenticate());
      dispatch(clearUser());
    }
  };
}

export function updateProfile(
  username,
  dni,
  email,
  name,
  surname,
  phoneNumber,
  birthDate
) {
  return async (dispatch, getState, api) => {
    await dispatch(updateProfileStart());

    const currentState = getState();
    const isFake = currentState.auth.isFake;

    if (isFake) {
      dispatch(updateProfileSuccess());
    }

    return api.masterdata
      .updateProfile(
        username,
        dni,
        email,
        name,
        surname,
        phoneNumber,
        birthDate
      )
      .then(async response => {
        const updatedProfile = {
          username: response.data.username,
          gender: response.data.gender,
          profile_picture: response.data.picture_url,
          neighbourhoodpropertiesregistrationcode: "",
          personid: response.data.id,
          name: response.data.name,
          family_name: response.data.surname,
          state: response.data.state,
          email: response.data.email,
          phone_number: response.data.phone_number,
          dni: response.data.dni,
          birthdate: response.data.birthdate,
          isunderage: response.data.is_under_age
        };

        await setUserData(updatedProfile);
        dispatch(saveUser(updatedProfile));

        dispatch(updateProfileSuccess());
      })
      .catch(error => {
        console.log("Error:", error);
        const message = get(
          error,
          "response.data.message",
          "Ocurrio un error al intentar modificar los datos de perfil."
        );
        dispatch(
          updateProfileFail(
            normalizeError(
              message,
              "Ocurrio un error al intentar modificar los datos de perfil."
            )
          )
        );
      });
  };
}

export function changePassword(actualPassword, newPassword) {
  return async (dispatch, getState, api) => {
    await dispatch(changePasswordStart());

    var accessToken = await getAuthToken();
    return api.auth
      .changePassword(accessToken, actualPassword, newPassword)
      .then(() => {
        dispatch(changePasswordSuccess());
      })
      .catch(error => {
        console.log("Error:", error);
        const message = get(
          error,
          "response.data.message",
          error.response.data.message
        );
        dispatch(
          changePasswordFail(
            normalizeError(
              message,
              "Ocurrio un error al intentar modificar la contraseña."
            )
          )
        );
      });
  };
}

export function forgotPassword(username) {
  return async (dispatch, getState, api) => {
    await dispatch(forgotPasswordEmailStart());

    return api.auth
      .forgotPassword(username)
      .then(() => {
        dispatch(forgotPasswordEmailSuccess());
      })
      .catch(error => {
        console.log("Error:", error);
        const message = get(
          error,
          "response.data.message",
          error.response.data.message
        );
        dispatch(
          forgotPasswordEmailFail(
            normalizeError(message, "Ocurrio un error al comprobar el email.")
          )
        );
      });
  };
}

export function forgotPasswordConfirmation(
  username,
  password,
  verificationCode
) {
  return async (dispatch, getState, api) => {
    await dispatch(forgotPasswordStart());
    return api.auth
      .forgotPasswordConfirmation(username, password, verificationCode)
      .then(() => {
        dispatch(forgotPasswordSuccess());
      })
      .catch(error => {
        console.log("Error:", error);
        const message = get(
          error,
          "response.data.message",
          error.response.data.message
        );
        dispatch(
          forgotPasswordFail(
            normalizeError(
              message,
              "Ocurrio un error al intentar generar la contraseña."
            )
          )
        );
      });
  };
}
