import { 
    fetchLastNewsFail,
    fetchLastNewsStart,
    fetchLastNewsSuccess,
    fetchNewsFail,
    fetchNewsStart,
    fetchNewsSuccess,
    
  } from "./actions";

  import { handleActions } from "redux-actions";

  const initialState = {
    currentNews:null,
    lastNews: [],
    fetchError:null,
    isFetchingNews:false,
    isFetchingLastNews:false
  };

  const news = handleActions(
    new Map([
      [
        fetchLastNewsFail,
        (state, action) => ({
          ...state,
          fetchError: action.payload.message,
          isFetchingLastNews: false
        })
      ],
      [
        fetchLastNewsStart,
        (state, action) => ({
          ...state,
          isFetchingLastNews: true
        })
      ],
      [
        fetchLastNewsSuccess,
        (state, action) => ({
          ...state,
          lastNews: action.payload.data,
          isFetchingLastNews: false
        })
      ],
      [
        fetchNewsFail,
        (state, action) => ({
          ...state,
          fetchError: action.payload.message,
          isFetchingNews: false
        })
      ],
      [
        fetchNewsStart,
        (state, action) => ({
          ...state,
          isFetchingNews: true
        })
      ],
      [
        fetchNewsSuccess,
        (state, action) => ({
          ...state,
          currentNews: action.payload.data,
          isFetchingNews: false
        })
      ]
    ]),
    initialState
  );
  
  export default news;