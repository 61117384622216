import { 
  fetchLastMoviesFail,
  fetchLastMoviesStart,
  fetchLastMoviesSuccess,
  fetchAllMoviesFail,
  fetchAllMoviesStart,
  fetchAllMoviesSuccess,
  fetchMostRelevantMoviesFail,
  fetchMostRelevantMoviesStart,
  fetchMostRelevantMoviesSuccess
} from "./actions";

import { handleActions } from "redux-actions";

const initialState = {
  moviesList: [],
  fetchError: null,
  isFetchingLastMovies: false,
  isFetchingAllMovies: false,
  isFetchingMostRelevantMovies: false,
  noMoreMovies: false,
  skip: 0
};

const movie = handleActions(
  new Map([
    [
      fetchLastMoviesFail,
      (state, action) => ({
        ...state,
        fetchError: action.payload.message,
        isFetchingLastMovies: false
      })
    ],
    [
      fetchLastMoviesStart,
      state => ({
        ...state,
        isFetchingLastMovies: true
      })
    ],
    [
      fetchLastMoviesSuccess,
      (state, action) => ({
        ...state,
        moviesList: action.payload.data,
        isFetchingLastMovies: false,
        noMoreMovies: action.payload.noMoreMovies,
        skip: action.payload.skip
      })
    ],
    [
      fetchAllMoviesFail,
      (state, action) => ({
        ...state,
        fetchError: action.payload.message,
        isFetchingAllMovies: false
      })
    ],
    [
      fetchAllMoviesStart,
      state => ({
        ...state,
        isFetchingAllMovies: true
      })
    ],
    [
      fetchAllMoviesSuccess,
      (state, action) => ({
        ...state,
        moviesList: action.payload.data,
        isFetchingAllMovies: false
      })
    ],
    [
      fetchMostRelevantMoviesFail,
      (state, action) => ({
        ...state,
        fetchError: action.payload.message,
        isFetchingMostRelevantMovies: false
      })
    ],
    [
      fetchMostRelevantMoviesStart,
      state => ({
        ...state,
        isFetchingMostRelevantMovies: true
      })
    ],
    [
      fetchMostRelevantMoviesSuccess,
      (state, action) => ({
        ...state,
        moviesList: action.payload.data,
        isFetchingMostRelevantMovies: false
      })
    ]
  ]),
  initialState
);

export default movie;