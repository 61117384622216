import { get } from 'lodash-es';
import { createActions } from "redux-actions";
import apiConstants from "constants/apiConstants";


// ------------------- //
// ACTIONS SINCRÓNICOS //
// ------------------- //

const actionOptions = {
    prefix: "NEWS", // Prefijo usado para los actions creados
    namespace: "-" // Separador usado entre el prefijo y el nombre del action
    // No usar "_" como separador, o se rompe el naming de las variables
  };

  export const { 
    fetchLastNewsStart,
    fetchLastNewsSuccess,
    fetchLastNewsFail,
    fetchNewsStart,
    fetchNewsSuccess,
    fetchNewsFail
  } = createActions(
    {
      FETCH_LAST_NEWS_START: () => undefined,
      FETCH_LAST_NEWS_SUCCESS: (data) => ({
        data: data
      }),
      FETCH_LAST_NEWS_FAIL: (error) => ({
        message: error
      }),
      FETCH_NEWS_START: () => undefined,
      FETCH_NEWS_SUCCESS: (data) => ({
        data: data
      }),
      FETCH_NEWS_FAIL: (error) => ({
        message: error
      }),
    },
    actionOptions
  );


// -------------------- //
// ACTIONS ASINCRÓNICOS //
// -------------------- //


export function fetchLastNews(
    newsCount,
    current
  ) {
      return async (dispatch, getState, api) => {
          await dispatch(fetchLastNewsStart());
          return api.news.getLastNews(newsCount,current).then(async (response) => {
              // Se retorna el valor de la api y se manda al metodo de success.
              dispatch(fetchLastNewsSuccess(response.data));
          }).catch((error) => {
              console.log("Error:",error);
              const message = get(
                  error,
                  'response.data.message',
                  'Ocurrio un error al intentar obtener noticias.',
              );
              dispatch(fetchLastNewsFail(message));
          });
      };
  }

  
export function fetchNews(
  current
) {
    return async (dispatch, getState, api) => {
        await dispatch(fetchNewsStart());
        return api.news.getCurrentNews(current).then(async (response) => {
            // Se retorna el valor de la api y se manda al metodo de success.
            dispatch(fetchNewsSuccess(response.data));
        }).catch((error) => {
            console.log("Error:",error);
            const message = get(
                error,
                'response.data.message',
                'Ocurrio un error al intentar obtener noticias.',
            );
            dispatch(fetchNewsFail(message));
        });
    };
}