import React from "react";
import { connect } from "react-redux";
import VideoPlayer from "containers/common/videoPlayer/videoPlayer";
import ChangePassword from "containers/pages/profilePage/changePassword/changePassword";

export const ModalTypes = {
  VIDEOPLAYER: "VIDEOPLAYER",
  CHANGEPASSWORD: "CHANGEPASSWORD"
};

const MODAL_COMPONENTS = {
  VIDEOPLAYER: VideoPlayer,
  CHANGEPASSWORD: ChangePassword
};

const ModalRoot = props => {
  if (!props.modalType) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[props.modalType];
  return <SpecificModal />;
};

const mapStateToProps = state => ({
  modalType: state.modal.modalType
});

export default connect(mapStateToProps)(ModalRoot);
