import { axiosAnonInstance, axiosInstance } from "./api.js";
import apiConstants from "constants/apiConstants.js"

const getUrl = url => {
  return `${apiConstants.foundation}${url}`;
};

export default {
  getMostRelevants: moviesCount =>
    axiosAnonInstance.get(
      `${getUrl(
        "/api/foundation/movies/get-most-relevant-movies"
      )}?count=${moviesCount}`
    ),
  getLastMovies: (moviesCount, skip) =>
    axiosAnonInstance.get(
      `${getUrl(
        "/api/foundation/movies/get-last-movies"
      )}?count=${moviesCount}&skip=${skip}`
    ),
  getAllMovies: () =>
    axiosAnonInstance.get(`${getUrl("/api/foundation/movies/get-all-movies")}`),
  getVideoUrl: idVideo =>
    axiosInstance.get(
      `${getUrl("/api/foundation/movies/get-url-video")}?id=${idVideo}`
    )
};
