/* eslint-disable react/prop-types */
import { connect } from "react-redux";
import React from "react";
import * as Yup from "yup";
import ChangePassword from "components/pages/profilePage/changePassword/changePassword";
import { changePassword } from "store/auth/actions";
import { hideModal, showModal } from "store/modal/actions";
import { useIsMount } from "../../../../utils/mountChecker";

require("moment/locale/es");

const getValidationSchema = () =>
  Yup.object().shape({
    actualPassword: Yup.string().required("REQUIRED"),
    newPassword: Yup.string()
      .required("REQUIRED")
      .min(8, "El password debe tener al menos 8 caracteres.")
      .test(
        "equal",
        "La contraseña debe tener al menos 1 mayúscula, 1 minúscula y 1 número.",
        function(v) {
          var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])");
          return strongRegex.test(v);
        }
      ),
    confirmedPassword: Yup.string()
      .required("REQUIRED")
      .test("equal", "Las contraseñas no coinciden.", function(v) {
        const ref = Yup.ref("newPassword");
        return v === this.resolve(ref);
      })
  });

const mapStateToProps = state => ({
  userInfo: state.auth.userInfo,
  changePasswordError: state.auth.changePasswordError,
  changePasswordSubmitting: state.auth.changePasswordSubmitting,
  passwordError: state.auth.passwordError,
  modal: state.modal
});

const mapDispatchToProps = dispatch => ({
  onHideModal: () => dispatch(hideModal()),
  onShowModal: (modalType, modalProps = {}) =>
    dispatch(showModal(modalType, modalProps)),
  changePassword: (actualPassword, newPassword) =>
    dispatch(changePassword(actualPassword, newPassword))
});

const getCurrentErrorMessage = (errors, touched) => {
  const fields = ["newPassword", "confirmedPassword"];

  for (let index = 0; index < fields.length; index++) {
    const element = fields[index];
    if (errors[element] && touched[element] && errors[element] === "REQUIRED"){
      return "Por favor, complete todos los campos.";
    }
  }

  for (let index = 0; index < fields.length; index++) {
    const element = fields[index];
    if (errors[element] && touched[element]){
      return errors[element];
    }
  }

  return "";
};

function ChangePasswordContainer(props) {
  const [currentPasswordVisible, setCurrentPasswordVisible] = React.useState(
    false
  );
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = React.useState(
    false
  );
  const currentPasswordRef = React.useRef();
  const passwordRef = React.useRef();
  const confirmPasswordRef = React.useRef();

  const isMount = useIsMount();

  const toggleCurrentPasswordVisibility = ref => {
    if (ref != null && ref.current != null) {
      if (ref.current.type === "text") {
        ref.current.type = "password";
        setCurrentPasswordVisible(false);
      } else {
        ref.current.type = "text";
        setCurrentPasswordVisible(true);
      }
    }
  };

  const togglePasswordVisibility = ref => {
    if (ref != null && ref.current != null) {
      if (ref.current.type === "text") {
        ref.current.type = "password";
        setPasswordVisible(false);
      } else {
        ref.current.type = "text";
        setPasswordVisible(true);
      }
    }
  };

  const toggleConfirmPasswordVisibility = ref => {
    if (ref != null && ref.current != null) {
      if (ref.current.type === "text") {
        ref.current.type = "password";
        setConfirmPasswordVisible(false);
      } else {
        ref.current.type = "text";
        setConfirmPasswordVisible(true);
      }
    }
  };

  const {
    changePasswordError,
    changePasswordSubmitting,
    passwordError,
    changePassword,
    onHideModal
  } = props;

  const getInitialValues = () => ({
    actualPassword: "",
    newPassword: "",
    confirmedPassword: ""
  });

  const handleSubmit = data => {
    changePassword(data.actualPassword, data.newPassword);
  };

  React.useEffect(() => {
    if (
      !isMount &&
      !changePasswordSubmitting &&
      !passwordError &&
      changePasswordError === ""
    )
      onHideModal();
  }, [changePasswordSubmitting, changePasswordError, passwordError]);

  const newProps = {
    getInitialValues,
    getValidationSchema,
    handleSubmit,
    getCurrentErrorMessage,
    changePasswordError,
    changePasswordSubmitting,
    onHideModal,
    currentPasswordVisible,
    toggleCurrentPasswordVisibility,
    passwordVisible,
    togglePasswordVisibility,
    confirmPasswordVisible,
    toggleConfirmPasswordVisibility,
    currentPasswordRef,
    passwordRef,
    confirmPasswordRef
  };

  return <ChangePassword {...newProps} />;
}

ChangePasswordContainer.propTypes = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordContainer);
