import { axiosAnonInstance, axiosInstance } from "./api.js";
import apiConstants from "constants/apiConstants.js";

const getUrl = url => {
  return `${apiConstants.authentication}${url}`;
}

export default {
  login: (username, password) =>
    axiosAnonInstance.post(getUrl("/api/authentication/cqa/Login"), {
      username,
      password
    }),
  changePassword: (accessToken, actualPassword, newPassword) =>
    axiosInstance.post(getUrl("/api/authentication/cqa/change-password"), {
      accessToken,
      actualPassword,
      newPassword
    }),
  forgotPassword: userName =>
    axiosAnonInstance.post(getUrl("/api/authentication/cqa/forgot-password"), {
      userName
    }),
  forgotPasswordConfirmation: (userName, newPassword, confirmationCode) =>
    axiosAnonInstance.post(
      getUrl("/api/authentication/cqa/forgot-password/confirmation"),
      {
        userName,
        new_password: newPassword,
        confirmation_code: confirmationCode
      }
    )
  // getuser: () => axiosInstance.get(getUrl('/login/getuser'))
};
