import React from "react";
import ReactModal from 'react-modal';
import { Player, BigPlayButton  } from 'video-react';
import 'assets/scss/videoPlayer/scss/video-react.scss';

const customStyles = {
  overlay : {
    zIndex: 20
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    width                 : '70%',
    transform             : 'translate(-50%, -50%)',
    backgroundColor: '#000000'
  }
};

ReactModal.setAppElement('#root');

export default function VideoPlayer(props) {
  const closeModal = () => {
    props.onHideModal();
  }

   return (
        <ReactModal
          isOpen={true}
          onRequestClose={closeModal}
          style={customStyles}
        >
         <Player 
            playsInline={true}
            fluid={true}
            autoPlay
            src={props.modal.modalProps.videoUrl}>
              <BigPlayButton position="center" />
          </Player>
        </ReactModal>
    );
}