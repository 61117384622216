import {
  fetchLastTalesFail,
  fetchLastTalesStart,
  fetchLastTalesSuccess,
  fetchAllTalesFail,
  fetchAllTalesStart,
  fetchAllTalesSuccess,
  fetchMostRelevantTalesFail,
  fetchMostRelevantTalesStart,
  fetchMostRelevantTalesSuccess,
  incrementSkipTales
} from "./actions";

import { handleActions } from "redux-actions";

const initialState = {
  talesList: [],
  fetchError: null,
  isFetchingLastTales: false,
  isFetchingAllTales: false,
  isFetchingMostRelevantTales: false,
  skip: 0,
  noMoreTales: false
};

const tale = handleActions(
  new Map([
    [
      fetchLastTalesFail,
      (state, action) => ({
        ...state,
        fetchError: action.payload.message,
        isFetchingLastTales: false
      })
    ],
    [
      fetchLastTalesStart,
      state => ({
        ...state,
        isFetchingLastTales: true
      })
    ],
    [
      fetchLastTalesSuccess,
      (state, action) => ({
        ...state,
        talesList: action.payload.data,
        isFetchingLastTales: false,
        noMoreTales: action.payload.noMoreTales,
        skip: action.payload.skip
      })
    ],
    [
      fetchAllTalesFail,
      (state, action) => ({
        ...state,
        fetchError: action.payload.message,
        isFetchingAllTales: false
      })
    ],
    [
      fetchAllTalesStart,
      state => ({
        ...state,
        isFetchingAllTales: true
      })
    ],
    [
      fetchAllTalesSuccess,
      (state, action) => ({
        ...state,
        talesList: action.payload.data,
        isFetchingAllTales: false
      })
    ],
    [
      fetchMostRelevantTalesFail,
      (state, action) => ({
        ...state,
        fetchError: action.payload.message,
        isFetchingMostRelevantTales: false
      })
    ],
    [
      fetchMostRelevantTalesStart,
      state => ({
        ...state,
        isFetchingMostRelevantTales: true
      })
    ],
    [
      fetchMostRelevantTalesSuccess,
      (state, action) => ({
        ...state,
        talesList: action.payload.data,
        isFetchingMostRelevantTales: false
      })
    ],
    [
      incrementSkipTales,
      (state, action) => ({
        ...state,
        skip: action.payload.data
      })
    ]
  ]),
  initialState
);

export default tale;