import React from 'react';
import styles from 'assets/scss/common/threeDotLoader/threeDotLoaderStyle.module.scss';

function ThreeDotLoader(props) {
  const { isLoading, children } = props;

  if (isLoading)
    return (<div className={styles["lds-ellipsis"]}>
    <div>
    </div>
    <div>
    </div>
    <div>
    </div>
    <div>
    </div>
  </div>);
  else 
    return (<>{children}</>);
}

export default ThreeDotLoader;