import React from 'react';
import { AppRoutes } from "./routes";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { checkAuthentication } from './store/auth/actions'

import ModalRootContainer from "containers/common/modalRoot/modalRoot";

import { Router } from "react-router-dom";

import { createBrowserHistory } from "history";

function mapStateToProps(state) {
  const { auth } = state;
  return {
    isAuthenticated: auth.isAuthenticated
  };
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    checkAuthentication
  }, dispatch);

const hist = createBrowserHistory();

// eslint-disable-next-line no-unused-vars
const unlisten = hist.listen((location, action) => {});

// eslint-disable-next-line no-unused-vars
function App(props) {
  React.useEffect(() => {
    props.checkAuthentication();

    return () => {
      unlisten();
    };
  },[]);

  const app = (
    <React.Fragment>
      <Router history={hist}>
        <AppRoutes isAuthenticated={props.isAuthenticated} />
      </Router>
      <ModalRootContainer />
    </React.Fragment>
  );

  return <div>{app}</div>;
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
