import { axiosAnonInstance, axiosInstance } from "./api.js";
import apiConstants from "constants/apiConstants.js";

const getUrl = url => {
  return `${apiConstants.foundation}${url}`;
};

export default {
  getLastNews: (newsCount, current) =>
    axiosAnonInstance.get(
      `${getUrl(
        "/api/foundation/news/get-last-news"
      )}?count=${newsCount}&currentNews=${current ? current : ""}`
    ),
  getCurrentNews: current =>
    axiosAnonInstance.get(
      `${getUrl(
        "/api/foundation/news/get-current-news"
      )}?currentNews=${current}`
    )
};
