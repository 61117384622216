export const getAuthToken = () => {
  return localStorage.getItem("token");
};

export const setAuthToken = (token) => {
  return localStorage.setItem("token", token);
};

export const getAuthData = async () => {
  var authenticated = await localStorage.getItem("authenticated");
  var token = await localStorage.getItem("token");
  var expiresIn = await localStorage.getItem("expiresIn");
  return { authenticated, token, expiresIn }
};

export const setAuthData = async (authenticated, token, expiresIn) => {
  await localStorage.setItem("authenticated", authenticated);
  await localStorage.setItem("token", token);
  await localStorage.setItem("expiresIn", expiresIn);
};

export const getUserData = async () => {
  try {
    let data = localStorage.getItem("userData");
    if (data == null) return null;
    return JSON.parse(data);
  } catch {
    return null;
  }
};

export const setUserData = async (userInfo) => {
  await localStorage.setItem("userData", JSON.stringify(userInfo));
};