const approvedErrors = [
  [
    "El nombre de usuario o contraseña no es valido",
    "El email o contraseña no es válido."
  ],
  ["El número de teléfono ya está en uso", null],
  ["El nombre de usuario ya está en uso", null],
  ["El email ya está en uso", null],
  ["El dni ya está en uso", null],
  ["Por favor complete los datos obligatorios", null],
  ["Su usuario se encuentra bloqueado", null],
  ["Debe ingresar una fecha de nacimiento válida", null],
  [
    "Email inexistente.",
    "Ups! El correo electrónico que ingresaste no está registrado. Debés realizar una donación para poder ingresar."
  ]
];

export const normalizeError = (error, defaultError) => {
  const errorIndex = approvedErrors.findIndex(element =>
    error.includes(element[0])
  );
  if (errorIndex >= 0) {
    if (approvedErrors[errorIndex][1] != null) {
      return approvedErrors[errorIndex][1];
    }

    // Reemplazo los saltos de linea por espacios
    error = error.replace(/\\n/g, "");
    // Quito los espacios finales, si los tiene
    error = error.replace(/\s+$/g, "");
    // Quito los puntos finales, si los tiene
    error = error.replace(/\.+$/g, "");
    // Agrego un punto al final
    error = `${error}.`;

    return error;
  } else {
    return defaultError == null ? "Ha ocurrido un error." : defaultError;
  }
};
