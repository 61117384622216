import { axiosAnonInstance, axiosInstance } from "./api.js";
import apiConstants from "constants/apiConstants.js"

const getUrl = (url) => {
  return `${apiConstants.masterdata}${url}`;
}

export default {
  updateProfile: (username, dni, email, name, surname, phoneNumber, birthDate) => axiosInstance.put(getUrl('/api/person/self'), {
    username,
    dni,
    email,
    name,
    surname,
    gender: 0,
    phoneNumber,
    birthDate
  })
};
