import { connect } from "react-redux";
import { hideModal, showModal } from "store/modal/actions";
import VideoPlayer from 'components/common/videoPlayer/videoPlayer';


const mapStateToProps = (state) => ({
  modal: state.modal
});

const mapDispatchToProps = (dispatch) => ({
  onHideModal: () => dispatch(hideModal()),
  onShowModal: (modalType, modalProps = {}) =>
    dispatch(showModal(modalType, modalProps))
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayer);
