import { get } from "lodash-es";
import { createActions } from "redux-actions";

// ------------------- //
// ACTIONS SINCRÓNICOS //
// ------------------- //

const actionOptions = {
  prefix: "TALE", // Prefijo usado para los actions creados
  namespace: "-" // Separador usado entre el prefijo y el nombre del action
  // No usar "_" como separador, o se rompe el naming de las variables
};

export const {
  fetchLastTalesStart,
  fetchLastTalesSuccess,
  fetchLastTalesFail,
  fetchMostRelevantTalesStart,
  fetchMostRelevantTalesSuccess,
  fetchMostRelevantTalesFail,
  fetchAllTalesStart,
  fetchAllTalesSuccess,
  fetchAllTalesFail,
  incrementSkipTales
} = createActions(
  {
    FETCH_LAST_TALES_START: () => undefined,
    FETCH_LAST_TALES_SUCCESS: (data, noMoreTales, skip) => ({
      data: data,
      noMoreTales: noMoreTales,
      skip: skip
    }),
    FETCH_LAST_TALES_FAIL: error => ({
      message: error
    }),
    FETCH_MOST_RELVANT_TALES_START: () => undefined,
    FETCH_MOST_RELVANT_TALES_SUCCESS: data => ({
      data: data
    }),
    FETCH_MOST_RELVANT_TALES_FAIL: error => ({
      message: error
    }),
    FETCH_ALL_TALES_START: () => undefined,
    FETCH_ALL_TALES_SUCCESS: data => ({
      data: data
    }),
    FETCH_ALL_TALES_FAIL: error => ({
      message: error
    }),

    INCREMENT_SKIP_TALES: data => ({
      data: data
    })
  },
  actionOptions
);

// -------------------- //
// ACTIONS ASINCRÓNICOS //
// -------------------- //
export function resetSkip() {
  return async (dispatch, getState, api) => {
    dispatch(resetSkip())
  }
}

export function fetchLastTales(talesCount) {
  return async (dispatch, getState, api) => {
    var skip = getState().tale.skip;
    await dispatch(fetchLastTalesStart());
    return api.tale
      .getLastTales(talesCount, skip)
      .then(async response => {
        var list = getState().tale.talesList;
        var data =
          list.length > 0 && skip > 0
            ? list.concat(response.data)
            : response.data;
        var noMoreTales = response.data.length < talesCount;
        dispatch(incrementSkipTales(talesCount + skip));
        dispatch(fetchLastTalesSuccess(data, noMoreTales, talesCount + skip));
      })
      .catch(error => {
        console.log("Error:", error);
        const message = get(
          error,
          "response.data.message",
          "Ocurrio un error al intentar obtener las películas."
        );

        dispatch(fetchLastTalesFail(message));
      });
  };
}

export function fetchMostRelevantTales(TalesCount) {
  return async (dispatch, getState, api) => {
    await dispatch(fetchMostRelevantTalesStart());
    return api.tale
      .getMostRelevantTales(TalesCount)
      .then(async response => {
        dispatch(fetchMostRelevantTalesSuccess(response.data));
      })
      .catch(error => {
        console.log("Error:", error);
        const message = get(
          error,
          "response.data.message",
          "Ocurrio un error al intentar obtener las películas."
        );
        dispatch(fetchMostRelevantTalesFail(message));
      });
  };
}

export function fetchAllTales() {
  return async (dispatch, getState, api) => {
    await dispatch(fetchAllTalesStart());
    return api.tale
      .getAllTales()
      .then(async response => {
        dispatch(fetchAllTalesSuccess(response.data));
      })
      .catch(error => {
        console.log("Error:", error);
        const message = get(
          error,
          "response.data.message",
          "Ocurrio un error al intentar obtener las películas."
        );
        dispatch(fetchAllTalesFail(message));
      });
  };
}
