import { combineReducers } from "redux";
import modal from "./modal/reducer";
import auth from "./auth/reducer";
import donation from "./donation/reducer";
import news from "./news/reducer";
import movie from "./movie/reducer";
import tale from "./tale/reducer";

export default combineReducers({
  modal,
  auth,
  donation,
  news,
  movie,
  tale
});
