import React from "react";
import styles from "assets/scss/pages/profilePage/changePasswordStyle.module.scss";
import { Formik } from "formik";
import { PropTypes } from "prop-types";
import clsx from "clsx";
import ThreeDotLoader from "components/common/threeDotLoader/threeDotLoader";
import ReactModal from "react-modal";

ReactModal.setAppElement('#root');

const customStyles = {
  overlay: {
    zIndex: 20
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "40%",
    transform: "translate(-50%, -50%)"
  }
};

export default function ChangePassword(props) {
  const closeModal = () => {
    props.onHideModal();
  };

  const {
    handleSubmit,
    getInitialValues,
    getValidationSchema,
    getCurrentErrorMessage,
    changePasswordError,
    changePasswordSubmitting,
    currentPasswordVisible,
    toggleCurrentPasswordVisibility,
    passwordVisible,
    togglePasswordVisibility,
    confirmPasswordVisible,
    toggleConfirmPasswordVisibility,
    currentPasswordRef,
    passwordRef,
    confirmPasswordRef
  } = props;

  return (
    <ReactModal isOpen={true} onRequestClose={closeModal} style={customStyles}>
      <Formik
        initialValues={getInitialValues()}
        validateOnChange={false}
        validationSchema={getValidationSchema()}
        onSubmit={handleSubmit}
      >
        {formikProps =>
          getFormContent({
            formikProps,
            getCurrentErrorMessage,
            changePasswordError,
            changePasswordSubmitting,
            currentPasswordVisible,
            toggleCurrentPasswordVisibility,
            passwordVisible,
            togglePasswordVisibility,
            confirmPasswordVisible,
            toggleConfirmPasswordVisibility,
            currentPasswordRef,
            passwordRef,
            confirmPasswordRef
          })
        }
      </Formik>
    </ReactModal>
  );
}

ChangePassword.propTypes = {
  handleSubmit: PropTypes.func,
  getInitialValues: PropTypes.func,
  getValidationSchema: PropTypes.func,
  getCurrentErrorMessage: PropTypes.any,
  changePasswordError: PropTypes.any,
  changePasswordSubmitting: PropTypes.any,
  changePasswordMessage: PropTypes.any,
  actualPassword: PropTypes.string,
  newPassword: PropTypes.string,
  confirmedPassword: PropTypes.string
};

function getFormContent({ formikProps, ...rest }) {
  const {
    handleSubmit,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
  } = formikProps;

  const {
    getCurrentErrorMessage,
    changePasswordError,
    changePasswordSubmitting,
    changePasswordMessage,
    currentPasswordVisible,
    toggleCurrentPasswordVisibility,
    passwordVisible,
    togglePasswordVisibility,
    confirmPasswordVisible,
    toggleConfirmPasswordVisibility,
    currentPasswordRef,
    passwordRef,
    confirmPasswordRef
  } = rest;

  return (
    <form
      onSubmit={handleSubmit}
      className={clsx(styles["box-container"], styles["stack-content"])}
    >
      <div className={styles["profile-data-container"]}>
        <div
          className={clsx(
            styles["profile-input-container"],
            styles["contain-text"]
          )}
        >
          <div className={clsx(styles["input-box"])}>
            <div
              onClick={() =>
                toggleCurrentPasswordVisibility(currentPasswordRef)
              }
            >
              <i
                className={clsx(
                  "fa",
                  { "fa-eye-slash": currentPasswordVisible },
                  { "fa-eye": !currentPasswordVisible },
                  styles["icon"]
                )}
              />
            </div>
            <span>Contraseña actual</span>
            <input
              className={clsx(styles["input-mask"], {[styles.error]: errors.actualPassword})}
              type="password"
              id="actualPassword"
              name="actualPassword"
              autoComplete="actualPassword"
              value={values.actualPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Contraseña actual"
              ref={currentPasswordRef}
            />
          </div>
          <div className={clsx(styles["input-box"])}>
            <div onClick={() => togglePasswordVisibility(passwordRef)}>
              <i
                className={clsx(
                  "fa",
                  { "fa-eye-slash": passwordVisible },
                  { "fa-eye": !passwordVisible },
                  styles["icon"]
                )}
              />
            </div>
            <span>Nueva contraseña</span>
            <input
              className={clsx(styles["input-mask"], {[styles.error]: errors.newPassword})}
              type="password"
              id="newPassword"
              name="newPassword"
              autoComplete="newPassword"
              value={values.newPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Nueva contraseña"
              ref={passwordRef}
            />
          </div>
          <div className={clsx(styles["input-box"])}>
            <div
              onClick={() =>
                toggleConfirmPasswordVisibility(confirmPasswordRef)
              }
            >
              <i
                className={clsx(
                  "fa",
                  { "fa-eye-slash": confirmPasswordVisible },
                  { "fa-eye": !confirmPasswordVisible },
                  styles["icon"]
                )}
              />
            </div>
            <span>Confirme contraseña</span>
            <input
              className={clsx(styles["input-mask"], { [styles.error]: errors.confirmedPassword })}
              type="password"
              id="confirmedPassword"
              name="confirmedPassword"
              autoComplete="confirmedPassword"
              value={values.confirmedPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Confirmar Password"
              ref={confirmPasswordRef}
            />
          </div>
        </div>
      </div>
      <div className={styles["row-container"]}>
        {getCurrentErrorMessage(errors, touched) !== "" ?
          <div>
            <span className={clsx(styles["error-message"], styles["self-r"])}>
              {getCurrentErrorMessage(errors, touched)}
            </span>
          </div>
          : null}
        {changePasswordMessage ?
          <div>
            <span className={clsx(styles["success-message"], styles["self-r"])}>
              {changePasswordMessage}
            </span>
          </div>
          : changePasswordError ?
            <div>
              <span className={clsx(styles["error-message"], styles["self-r"])}>
                {changePasswordError}
              </span>
            </div>
            : null}
        <button
          type="submit"
          className={clsx(
            styles["btn"],
            styles["long-1"],
            styles["btn-blue"],
            styles["self-r"]
          )}
          value="Submit"
        >
          <ThreeDotLoader isLoading={changePasswordSubmitting}>
            Guardar
          </ThreeDotLoader>
        </button>
      </div>
    </form>
  );
}
