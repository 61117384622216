import React from "react";

const MainPage = React.lazy(() => import("containers/pages/mainPage/mainPage"));

const DonatePage = React.lazy(() =>
  import("containers/pages/donatePage/donatePage")
);

const ThanksPage = React.lazy(() =>
  import("components/pages/thanksPage/thanksPage")
);

const ProfilePage = React.lazy(() =>
  import("components/pages/profilePage/profilePage")
);

const NewsPage = React.lazy(() => import("containers/pages/newsPage/newsPage"));

const TalesPage = React.lazy(() =>
  import("containers/pages/talesPage/talesPage")
);

const MoviesPage = React.lazy(() =>
  import("containers/pages/moviesPage/moviesPage")
);

const routes = [
  {
    path: "/home",
    name: "Página principal",
    icon: null,
    component: MainPage,
    loggedIn: false,
    public: true
  },
  {
    path: "/donate",
    name: "Donación",
    icon: null,
    component: DonatePage,
    loggedIn: false,
    public: true
  },
  {
    path: "/thanks",
    name: "Agradecimiento",
    icon: null,
    component: ThanksPage,
    loggedIn: false,
    public: true
  },
  {
    path: "/profile",
    name: "Perfil",
    icon: null,
    component: ProfilePage,
    loggedIn: false,
    public: true
  },
  {
    path: "/newsdetail",
    name: "Novedades",
    icon: null,
    component: NewsPage,
    loggedIn: false,
    public: true
  },
  {
    path: "/tales",
    name: "Cuentos",
    icon: null,
    component: TalesPage,
    loggedIn: false,
    public: true
  },
  {
    path: "/movies",
    name: "Peliculas",
    icon: null,
    component: MoviesPage,
    loggedIn: false,
    public: true
  }
];

export default routes;
