import { axiosAnonInstance, axiosInstance } from "./api.js";
import apiConstants from "constants/apiConstants.js"

const getUrl = (url) => {
  return `${apiConstants.foundation}${url}`;
}

export default {
    getMostRelevantsTales: (storiesCount) => axiosAnonInstance.get(`${getUrl('/api/foundation/stories/get-most-relevant-stories')}?count=${storiesCount}`),
    getLastTales: (storiesCount, skip) => axiosAnonInstance.get(`${getUrl('/api/foundation/stories/get-last-stories')}?count=${storiesCount}&skip=${skip}`),
    getAllTales: () => axiosAnonInstance.get(`${getUrl('/api/foundation/stories/get-all-stories')}`),
    getVideoUrl: (idVideo) => axiosInstance.get(`${getUrl('/api/foundation/stories/get-url-video')}?id=${idVideo}`)
    // TO-DO: Cambiar urlVideo a no anonimo
  };