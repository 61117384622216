import axios from "axios";
import auth from "./auth";
import donation from "./donation";
import news from "./news";
import movie from "./movie";
import tale from "./tale";
import masterdata from "./masterdata";

import { getAuthToken } from "../utils/auth.js";
import donationConstants from "../constants/donationConstants";
import { logOut } from "store/auth/actions";

//export const API_URL = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}`;
export const NEIGHBOURHOODGUID = donationConstants.neighbourhoodGuid;

// ----------------- //
// Instancia anónima //
// ----------------- //

export const axiosAnonInstance = axios.create({
  // baseURL: API_URL
});

axiosAnonInstance.interceptors.response.use(response => {
  return response;
});

axiosAnonInstance.interceptors.request.use(async config => {
  config.headers["x-barrio"] = NEIGHBOURHOODGUID;
  return config;
});

// ----------------- //
// Instancia con token //
// ----------------- //

export const axiosInstance = axios.create({
  // baseURL: API_URL
});

export const configureUnauthorizedInterceptor = store => {
  axiosInstance.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (
        error.response.status === 401 ||
        error.response.data.message.includes("access")
      ) {
        store.dispatch(logOut());
        window.location.href = "/";
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );
};

axiosInstance.interceptors.request.use(async config => {
  const token = getAuthToken();
  config.headers["Authorization"] = `Bearer ${token}`;
  config.headers["x-barrio"] = NEIGHBOURHOODGUID;
  return config;
});

export default {
  auth,
  donation,
  news,
  movie,
  tale,
  masterdata
};
