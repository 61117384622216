import React from 'react';
import ReactDOM from 'react-dom';
import './assets/scss/indexStyle.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

// import '@fortawesome/fontawesome-free/js/all';
// import './assets/font-awesome/css/all.css';
import "font-awesome/css/font-awesome.min.css";

import { Provider } from "react-redux";
import { store } from "./store/store";

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
